<template>
  <a-row :gutter="25">
    <h3 class="title-view ml-2">1. Escanea el código QR</h3>
  </a-row>
  <a-row class="mt-4" style="justify-content: center;">
    <a-col :xxl="12" :lg="12" :md="12" :sm="24" :xs="24">
      <sdCards>
        <a-row>
          <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
              <div style="color: #E8394D;" class="frame"></div>
              <div class="loading-indicator" v-if="loading">
                Cargando recursos de cámara...
                <br>
                <a-spin size="small" />
              </div>
              <div v-if="validationSuccess && !loading" class="validation-success">
                ¡QR válido!
                <img
                  width="40"
                  height="40" 
                  style="position: relative;left: 45%;" 
                  :src="require('@/static/img_events/SEJ_Eventos_qr validado.svg')" alt="event date" />
              </div>

              <div v-if="validationFailure && !loading" class="validation-failure">
                QR no válido...
                 <img
                  width="40"
                  height="40" 
                  style="position: relative;left: 45%;" 
                  :src="require('@/static/img_events/SEJ_error_qr.png')" alt="event date" />
              </div>

              <div v-if="validationPending && !loading" class="validation-pending">
                Validando QR...
              </div>
            </qrcode-stream>
          </a-col>
        </a-row>
      </sdCards>
    </a-col>
  </a-row>
</template>
<script>

import { QrcodeStream } from 'vue3-qrcode-reader';
import { Notification } from 'ant-design-vue';
import { registerAttendanceUser } from '@/services/attendanceRecord';

const QrCameraComponent = {
  name: 'QrCameraComponent',
  components: {
    QrcodeStream
  },
  emits: ["responseSuccessQR"],
  props: {
    idEvent: null
  },
  data () {
    return {
      result: null,
      error: '',
      loading: false,
      isValid: undefined,
      camera: 'auto',
    }
  },
  computed: {
    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
    resetValidationState () {
      this.isValid = undefined
    },

    async onDecode (result) {
      let responseRequest = null;
      this.result = result;

      if(result != ""){
        this.turnCameraOff();
        responseRequest = await this.registerAttendanceUserFunc(result);

        await this.timeout(1000);
        
        if(responseRequest.isvalid) {
          this.isValid = true;
          await this.timeout(1000);
          this.$emit('responseSuccessQR', { isValid: responseRequest.isvalid, data: responseRequest.data })
        } else {
          this.isValid = false;
          await this.timeout(2000);
          this.turnCameraOn()
        }
        
      }
    },

    async registerAttendanceUserFunc(hash){
      let isvalid = false;
      let data = [];
      await registerAttendanceUser(hash, this.idEvent).then((response) => {
        if(response.success){
          data = response.data;
          isvalid = true;
        } else {
          this.showNotification('error', 'Error', response.message);
        }
      }).catch(() => {
        this.showNotification('error', 'Error', 'Oops ocurrió un error inesperado');
      })
      return {isvalid, data}
    },

    async onInit (promise) {
      this.loading = true;
      try {
        await promise  
        .catch((error) => {
          this.handleErrorCamera(error);
        })
        .then(this.resetValidationState)
      } catch (error) {
        this.handleErrorCamera(error)
      } finally {
        this.loading = false
      }
    },

    handleErrorCamera(error) {
      if (error.name === 'NotAllowedError') {
        this.error = "Necesita otorgar permiso de acceso a la cámara"
      } else if (error.name === 'NotFoundError') {
        this.error = "No hay cámara en este dispositivo"
      } else if (error.name === 'NotSupportedError') {
        this.error = "Se requiere contexto seguro (HTTPS, localhost)"
      } else if (error.name === 'NotReadableError') {
        this.error = "¿La cámara ya está en uso?"
      } else if (error.name === 'OverconstrainedError') {
        this.error = "Las cámaras instaladas no son adecuadas"
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = "Stream API no es compatible con este navegador"
      } else if (error.name === 'InsecureContextError') {
        this.error = 'El acceso a la cámara solo está permitido en un contexto seguro. Utilice HTTPS o localhost en lugar de HTTP.';
      } else {
        this.error = `ERROR: Camera error (${error.name})`;
      }
      this.showNotification('error', 'Error', this.error);
    },

    turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    showNotification (type, message, description) {
      Notification[type]({
        message,
        description,
      });
    }

  },
  async setup() {
    return {
    };
  },
};

export default QrCameraComponent;
</script>
<style scoped>
.loading-indicator {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  height: 5rem;
  padding: 1rem;
  margin-top: 7rem;
}
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
.title-view{
  color: #404040;
  font-family: 'Nutmeg Bold';
  font-size: 18px;
}
</style>
